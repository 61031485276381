import Home from "./Home";
import Channel from './Channel'
import Catchup from './Catchup'
import Login from './Login'
import Showpage from './Showpage'
import Settings from './Settings'
import Search from './Search'
import Pin from './Pin'
import WatchList from './WatchList'
import StreamaSettings from './StreamaSettings'
import StreamaAppList from './StreamaAppList'
import Onboarding from './Onboarding'
import DeprecatedDevice from './DeprecatedDevice'
import FirmwareUpgradeHisense from "./FirmwareUpgradeHisense";

export { Home, Channel, Catchup, Login, Onboarding, Showpage, Search, Settings, Pin, WatchList, StreamaSettings, StreamaAppList, DeprecatedDevice,FirmwareUpgradeHisense };
