import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TvProfileConfirm } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages/TvProfiles';
import { GET_PROFILES_LIST, SAVE_PROFILE, EDIT_PROFILE, DELETE_PROFILE, ABORT_PROFILE, PROFILES_ERROR_CLEAR, GET_PROFILES_LIST_ERROR } from '@connected-video-web/dstv-frontend-services/dist/actions'
import { KeyCode } from '../../../keyCodeMapping';
import * as helper from './helper'
import { profile } from '@connected-video-web/dstv-frontend-services/dist/constants/resource'
import { TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { scroll } from '../../../events';
import { networkPayload } from '../../helper';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { postSegmentData } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import dstvLogo from '@connected-video-web/dstv-frontend-components/dist/Assets/Images/dstv_login_logo_new.png';

class ProfileConfirm extends Component {
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.navigation);
        window.addEventListener('wheel', scroll(this.navigation));
        let path = this.props.history.location.pathname.split('/')
        this.state = {
            avatarFocused: false,
            nameFocused: false,
            saveFocused: true,
            deleteFocused: false,
            isDelete: path[2] === 'delete',
            prevState: {}
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.navigation);
        window.removeEventListener('wheel', this.scroll);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.profiles.profileSaved || nextProps.profiles.profileDeleted || nextProps.profiles.isAborted) {
            if (nextProps.profiles.profileDeleted) window.profileHistory = []
            helper.pushProfileListScreen(this.props.history)
        }
        if (helper.checkProfileErrors(nextProps) && !helper.checkProfileErrors(this.props)) {
            let state = { ...this.state }
            this.setState({
                ...this.state,
                prevState: state,
                avatarFocused: false,
                nameFocused: false,
                saveFocused: false,
                deleteFocused: false,
            })
        }

        if (nextProps && nextProps.profiles && nextProps.profiles.saveProfileerror && (nextProps.profiles.serverError)) {
            postAPIErrorToSegment(nextProps.profiles.saveProfileerror, { errorMessage: nextProps.profiles.serverError })
        }
    }

    scroll = (e) => {
        scroll(this.navigation)(e)
    }

    navigation = (e) => {
        if (!(this.state.saveFocused || this.state.nameFocused || this.state.avatarFocused || this.state.deleteFocused || this.props.profiles.error ||
            helper.checkProfileErrors(this.props))) {
            this.setState({ ...this.state.prevState })
        } else
            if (!helper.checkProfileErrors(this.props)) {
                switch (KeyCode(e.keyCode)) {
                    case 'UP':
                        this.moveFocusUp()
                        break;
                    case 'DOWN':
                        this.moveFocusDown()
                        break;
                    case 'LEFT':
                        this.moveFocusLeft()
                        break;
                    case 'RIGHT':
                        this.moveFocusRight()
                        break;
                    case 'SELECT':
                        this.onSelect()
                        break;
                    case 'BACK':
                        this.onBack()
                        break;
                }
            } else {
                switch (KeyCode(e.keyCode)) {
                    case 'SELECT':
                        this.errorOnSelect()
                        break;
                    case 'BACK':
                        this.errorOnBack()
                        break;
                }
            }
    }

    moveFocusUp = () => {
        if (this.state.saveFocused) {
            this.setState({ ...this.state, nameFocused: true, saveFocused: false })
        } else if (this.state.nameFocused) {
            this.setState({ ...this.state, avatarFocused: true, nameFocused: false })
        } else if (this.state.deleteFocused) {
            this.moveFocusLeft();
        }
    }

    moveFocusDown = () => {
        if (this.state.avatarFocused) {
            this.setState({ ...this.state, nameFocused: true, avatarFocused: false })
        } else if (this.state.nameFocused) {
            this.setState({ ...this.state, saveFocused: true, nameFocused: false })
        } else if (this.state.saveFocused)  {
            this.moveFocusRight();
        }
    }

    moveFocusLeft = () => {
        if (this.state.deleteFocused) {
            this.setState({ ...this.state, saveFocused: true, deleteFocused: false })
        }
    }

    moveFocusRight = () => {
        if (this.state.saveFocused && this.state.isDelete) {
            this.setState({ ...this.state, saveFocused: false, deleteFocused: true })
        }
    }
    onSelect = () => {
        if (this.state.avatarFocused) {
            helper.pushProfileAvatarScreen(this.props.history, window.profileHistory[0])
        } else if (this.state.nameFocused) {
            helper.pushProfileNameScreen(this.props.history, window.profileHistory[0])
        } else if (this.state.deleteFocused && !this.props.profiles.isLoading) {
            this.updateProfileDetails('DELETE', { profileId: window.profileHistory[0].id })
        } else if (this.state.saveFocused) {
            if (this.state.isDelete) {
                postSegmentData(profile.profileConfirm.actionButton.delete[0], profile.profileConfirm.title.delete.cancel)
                this.props.history.goBack()
            } else {
                let payload = {
                    alias: window.profileHistory[0].title,
                    avatarId: window.profileHistory[0].avatar.id,
                    profileId: window.profileHistory[0] && window.profileHistory[0].profileId ? window.profileHistory[0].profileId : "",
                }

                if (window.profileHistory[0].isNewProfile && !payload.profileId) {
                    if (!this.props.profiles.savingProfile)
                        this.updateProfileDetails('SAVE', payload)
                } else if (!this.props.profiles.savingProfileEdits) {
                    this.updateProfileDetails('UPDATE', {
                        ...payload,
                        profileId: payload.profileId ? payload.profileId : window.profileHistory[0].id
                    })
                }
            }
        }
    }

    onBack = () => {
        if ((!window.profileHistory[0].isDefault && !window.profileHistory[0].isNewProfile) || this.state.isDelete) { //non default edit
            helper.pushProfileListScreen(this.props.history, window.profileHistory[0])
        } else {
            window.profileHistory.shift()
            this.props.history.goBack()
        }
        this.props.abortProfile()
    }

    getTitle = (title) => {
        if (this.state.isDelete) return title.delete.cancel
        if (this.state.avatarFocused) return title.edit.avatar
        if (this.state.nameFocused) return title.edit.name
        if (window.profileHistory &&
            window.profileHistory[0].isNewProfile) return title.new.save
        return title.edit.save
    }

    getActionButton = () => {
        let action = profile.profileConfirm.actionButton[this.state.isDelete ? 'delete' : 'save']
        let actionButton = [{ type: action[0].toUpperCase(), value: action[0], isTvFocus: this.state.saveFocused }]
        if (this.state.isDelete) {
            actionButton.push({ type: action[1].split(' ')[0].toUpperCase(), value: action[1], isTvFocus: this.state.deleteFocused })
        }
        return actionButton
    }

    onClick = (item) => {
        switch (item.type) {
            case 'AVATAR':
                if (!this.state.isDelete)
                    this.setState({ ...this.state, avatarFocused: true, nameFocused: false, saveFocused: false }, this.onSelect)
                break;
            case 'NAME':
                if (!this.state.isDelete)
                    this.setState({ ...this.state, avatarFocused: false, nameFocused: true, saveFocused: false }, this.onSelect)
                break;
            case 'SAVE':
                this.setState({ ...this.state, avatarFocused: false, nameFocused: false, saveFocused: true }, this.onSelect)
                break
            case 'DELETE':
                this.setState({ ...this.state, deleteFocused: true, saveFocused: false }, this.onSelect)
                break
            case 'CANCEL':
                this.setState({ ...this.state, deleteFocused: false, saveFocused: true }, this.onSelect)
                break
        }
    }

    updateProfileDetails = (type, payload) => {
        if (internetIsConnected()) {
            switch (type) {
                case 'SAVE':
                    postSegmentData(profile.profileConfirm.actionButton.save[0], window.profileHistory[0].isNewProfile ? profile.profileConfirm.title.new.save : profile.profileAvatar.title.edit.save)
                    return this.props.saveProfile(payload)
                case 'UPDATE':
                    postSegmentData(profile.profileConfirm.actionButton.save[0], profile.profileConfirm.title.edit.save)
                    return this.props.updateProfile(payload)
                case 'DELETE':
                    postSegmentData(profile.profileConfirm.actionButton.delete[1], profile.profileConfirm.title.delete.cancel)
                    return this.props.deleteProfile(payload)
            }
        } else {
            this.props.throwNetworkError({
                error:
                    networkPayload({
                        callback: () => { this.updateProfileDetails(type, payload) },
                        toggleText: (window.profileHistory && window.profileHistory[0] && window.profileHistory[0].isNewProfile && !window.profileHistory[0].addButtonClicked) ? true : false
                    })
            })
        }
    }

    errorOnSelect = () => {
        if (((this.props.profiles.saveProfileError && this.props.profiles.saveProfileError.isVisible) ||
            (this.props.profiles.editProfileError && this.props.profiles.editProfileError.isVisible)) &&
            helper.checkGenericError(this.props)) {
            this.props.hideGenericError()
            let error = this.props.profiles.saveProfileError || this.props.profiles.editProfileError
            if (error.key === 'NAME') {
                helper.pushProfileNameScreen(this.props.history, window.profileHistory[0])
            } else if (error.key === 'AVATAR') {
                helper.pushProfileAvatarScreen(this.props.history, window.profileHistory[0])
            } else {
                helper.pushProfileListScreen(this.props.history, window.profileHistory[0])
            }
        }
    }

    errorOnBack = () => {
        this.props.hideGenericError()
        this.setState({ ...this.state.prevState })
    }

    render() {
        let actionButton = this.getActionButton()
        let data = {
            title: { value: this.getTitle(profile.profileConfirm.title) },
            avatar: { type: 'AVATAR', src: window.profileHistory && window.profileHistory[0] && window.profileHistory[0].avatar.uri, isTvFocus: this.state.avatarFocused },
            name: { type: 'NAME', value: window.profileHistory && window.profileHistory[0] && window.profileHistory[0].title, isTvFocus: this.state.nameFocused },
            actionButton: actionButton,
            description: profile.profileConfirm.description.delete
        }
        let error = ''
        if (helper.checkGenericError(this.props) ||
            (this.props.profiles.error && this.props.profiles.error.isNetworkError) ||
            (this.props.profiles.editProfileError || this.props.profiles.saveProfileError))
            error = <TvError {...this.props.profiles.error || this.props.profiles.saveProfileError || this.props.profiles.editProfileError} onSelected={[this.errorOnSelect, this.errorOnBack]} />
        return (
            <React.Fragment>
                <TvProfileConfirm
                    data={data}
                    isDelete={this.state.isDelete}
                    isLoading={this.props.profiles.savingProfileEdits || this.props.profiles.savingProfile || this.props.profiles.isLoading}
                    onClick={this.onClick}
                    logo={dstvLogo}
                />
                {error}
            </React.Fragment>)
    }
}


/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        profiles: state.profiles,
    }
};

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    getProfiles: () => dispatch({ type: GET_PROFILES_LIST }),
    saveProfile: (payload) => dispatch({ type: SAVE_PROFILE, payload }),
    updateProfile: (payload) => dispatch({ type: EDIT_PROFILE, payload }),
    deleteProfile: (payload) => dispatch({ type: DELETE_PROFILE, payload }),
    abortProfile: () => dispatch({ type: ABORT_PROFILE }),
    hideGenericError: () => dispatch({ type: PROFILES_ERROR_CLEAR }),
    throwNetworkError: (payload) => dispatch({ type: GET_PROFILES_LIST_ERROR, payload }),
    callAction: (type) => dispatch({ ...type })
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileConfirm);