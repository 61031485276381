import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DeprecatedDeviceMessage } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';

class FirmwareUpgradeHisense extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <React.Fragment>
            <DeprecatedDeviceMessage
                message={"Dear customer, Your television requires the necessary firmware update to ensure uninterrupted access to the DSTV application.\n Please take a moment to perform the update via your television's settings.\n How to update Access the Settings menu on your television, then choose Support and select System Upgrade.\n Click on Check Firmware Update and follow the on-screen instructions.\n Should you encounter any difficulties during the ‘update’ process, please contact the Hisense South Africa Service Team : https://hisense.co.za/contact/"}
            />
        </React.Fragment>
    }
}

export default connect(null, null)(FirmwareUpgradeHisense);