import React, { Component } from 'react'
import { secureLocalStorage } from '@connected-video-web/dstv-frontend-services/dist/Utils/secureStorage';
import { connect } from 'react-redux'
import { OPEN_PLAYER, EDITORIALS, PLAY_EPISODE, SHOW_TOAST_MESSAGE, HIDE_TOAST_MESSAGE, UPDATE_SHOWPAGE_TO_WATCH_STATE, UPDATE_SHOWPAGE } from '@connected-video-web/dstv-frontend-services/dist/actions'
import { TvShowPage, TvError } from '@connected-video-web/dstv-frontend-components/dist/Components/Pages';
import { styledEditorial } from '../styledComponents'
import { pageType } from '@connected-video-web/dstv-frontend-services/dist/constants/constants';
import StreamHelper from '@connected-video-web/dstv-frontend-services/dist/Utils/streamHelper';
import { internetIsConnected } from '@connected-video-web/dstv-frontend-services/dist/Utils/networkHelper';
import { networkPayload } from '../helper'
import { postSegmentData } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { postAPIErrorToSegment } from '@connected-video-web/dstv-frontend-middleware/dist/SpatialNavigation/segmentHelper';
import { exoPlayerEnabled } from '@connected-video-web/dstv-frontend-services/dist/Utils/flagrHelper';
import styles from '../../base-styles/playerui.scss';
import { Loader } from '@connected-video-web/dstv-frontend-components/dist/Components/Core';
import { startPolling, stopPolling } from '@connected-video-web/dstv-frontend-services/dist/Utils/schedulerHelper';

const semver = require('semver')
class Showpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUWBCalled: false,
            preferenceCalled: false,
            tryThisChangedShowpage: false,
            isExoPlayer: false,
            assetId: null,
            assetType: null,
        };

    }
    onChannelCardSelected = (channel) => {
        if (internetIsConnected()) {
            let array = []
            array.push(channel)
            this.props.openPlayer({ type: PLAY_EPISODE, episode: { items: array } });
            let item = { id: channel.id, editorialItemType: 'Video' }
            this.props.history.push({ pathname: StreamHelper.GetPayerUrl(pageType.catchup, item), state: { detail: channel } });
        } else {
            this.props.throwNetworkError(
                networkPayload({
                    callback: () => { this.onChannelCardSelected(channel) }
                }))
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.showpage && nextProps.showpage.error && nextProps.showpage.serverError) {
            postAPIErrorToSegment(nextProps.showpage.error, nextProps.showpage.serverError)
        } else if (nextProps && !nextProps.showpage.data) {
            this.setState({ preferenceCalled: false });
        }
        if (nextProps.showpage && nextProps.showpage.genRef !== this.props.showpage.genRef) {
            this.setState({ preferenceCalled: false });
        }

        let currentIsPlayer = !!(this.props.location.pathname.indexOf("play") > -1);
        let nextIsPlayer = !!(nextProps.location.pathname.indexOf("play") > -1);
        let nextIsSettings = !!(nextProps.location.pathname.indexOf("settings") > -1);
        let nextIsLiveTV = !!(nextProps.location.pathname.indexOf("livetv") > -1);

        if (currentIsPlayer && (!nextIsPlayer && !nextIsSettings && !nextIsLiveTV)) {
            this.isExoPlayer = window.isAndroidTV && exoPlayerEnabled();
            if (!window.isAndroidTV || (window.isAndroidTV && !this.isExoPlayer)) {
                this.updateShowPage();
            }
        }
    }

    componentDidMount() {
        window.addEventListener('TryThisShowPagesEvent', this.updateShowPage);
        window.addEventListener('PlayerClosedEvent', this.updateShowPage);
    }

    componentWillUnmount() {
        window.removeEventListener('TryThisShowPagesEvent', this.updateShowPage);
        window.removeEventListener('PlayerClosedEvent', this.updateShowPage);
    }

    componentDidUpdate(prevProps) {
        if (prevProps && prevProps.showpage && prevProps.showpage.data && prevProps.showpage.data[1] && prevProps.showpage.data[1].moreInfoEndpoint) {
            if (!prevProps.showpage.data[1].preferences && (!prevProps.showpage.isError) && !prevProps.moreinfo.isLoading && !this.state.preferenceCalled) {
                this.props.callMoreInfoEndPoint(prevProps.showpage.data[1].moreInfoEndpoint)
                this.setState({ preferenceCalled: true });
            }
        }
        if (prevProps.location.pathname !== this.props.location.pathname) {
            let pathArray = window.location.href.split("/").reverse();
            if ((pathArray[1] == "video") || (pathArray[1] == "program")) {
                this.setAssetDetails();
            }
        }
    }

    setAssetDetails = (assetId, assetType) => {
        if (assetId && assetType) {
            this.setState({ assetId: assetId, assetType: assetType })
        } else {
            let pathArray = window.location.href.split("/").reverse();
            this.setState({ assetId: pathArray && pathArray[0], assetType: pathArray && pathArray[1] })
        }
    }

    onErrorActionSelected = (e) => {
        if (this.props.showpage.error.callback) {
            let type = this.props.showpage.error.callback(internetIsConnected())
            if (type) this.props.callAction(type)
        } else {
            this.props.callAction(e.retryAction)
        }

    }

    onErrorBackSelected = (e) => {
        this.props.clearError(this.props.showpage.error)
    }

    onButtonSelected = (e) => {
        let type = ''
        if (e.preferences.isWatchlistItem) {
            postSegmentData('Remove from My List', 'Show Page')
            type = 'WATCH_LIST_DELETE'
            if (internetIsConnected()) {
                this.props.getButtonAction(type, e.preferences.watchlistToggleEndpoint, e.watchListBody)
            } else {
                this.props.throwNetworkError(
                    networkPayload({
                        callback: () => { this.onButtonSelected(e) }
                    }))
            }
        } else {
            postSegmentData('Add to My List', 'Show Page')
            type = 'WATCH_LIST_ADD'
            if (internetIsConnected()) {
                this.props.getButtonAction(type, e.preferences.watchlistToggleEndpoint, e.watchListBody)
            } else {
                this.props.throwNetworkError(
                    networkPayload({
                        callback: () => { this.onButtonSelected(e) }
                    }))
            }
        }
    }

    onPlaySelected = (e) => {
        if (internetIsConnected()) {
            this.props.getTryThisData(e.linkTo)
        } else {
            this.props.throwNetworkError(
                networkPayload({
                    callback: () => { this.onPlaySelected(e) }
                }))
        }
    }

    onEpisodeClick = (e) => {
        if (internetIsConnected()) {
            this.props.getEpisodeOverlay(e)
        } else {
            this.props.throwNetworkError(
                networkPayload({
                    callback: () => { this.props.onEpisodeClick(e) }
                }))
        }
    }

    startPolling = (movieObj, toastMessage = { showToast: true }) => {
        let type;
        startPolling(movieObj, (e) => {
            if (toastMessage.showToast) {
                let poster = e && e.image && e.image.POSTER ? e.image.POSTER : "";
                type = 'SHOW_TOAST_MESSAGE'
                this.props.showOrHideToastMessage({ type: type, body: { status: true, poster: poster } });
                this.props.updateShowpageScreen({ type: UPDATE_SHOWPAGE_TO_WATCH_STATE, body: { data: e } });
            }
        })
    }

    isBoxOffice = data => {
        let billboardData = data.filter(item => item.renderType.toLowerCase() === 'billboard').pop();
        if (billboardData && billboardData.items && billboardData.items[0] && billboardData.items[0].type && billboardData.items[0].type === 'boxoffice_movies') {
            return true
        }
        return false
    }

    updateShowPage = (e) => {
        let isTryThis = !!(e && e.type === "TryThisShowPagesEvent");

        if (isTryThis) {
            this.setState({ tryThisChangedShowpage: true })
            this.setAssetDetails(e.detail.videoId, e.detail.videoType);
            setTimeout(() => {
                this.setState({ tryThisChangedShowpage: false })
            }, 5000);
        }

        if (isTryThis || !this.state.tryThisChangedShowpage && (this.props.showpage && this.props.showpage.data)) {
            if (!(this.props.showpage &&
                this.props.showpage.data &&
                this.props.showpage.data[0] &&
                this.props.showpage.data[0].items &&
                this.props.showpage.data[0].items[0] &&
                this.props.showpage.data[0].items[0].type &&
                this.props.showpage.data[0].items[0].type === "boxoffice_movies")) {
                if (this.state.assetId && this.state.assetType) {
                    this.props.updateShowPage({
                        id: this.state.assetId,
                        type: this.state.assetType,
                        isFromTryThis: isTryThis ? isTryThis : false,
                    })
                }
            }
        }
    }

    render() {
        let error, showpage
        const { watchButtonState } = this.props.showpage
        const resumeState = watchButtonState && watchButtonState.resume_state ? watchButtonState.resume_state : undefined
        let isLoading = this.props.showpage && this.props.showpage.isLoading === false ? false : true;
        if (this.props.showpage && this.props.showpage.data) {
            if (this.props.showpage && this.props.showpage.error && this.props.showpage.error.isVisible) {
                error = <TvError {...this.props.showpage.error} onSelected={[this.onErrorActionSelected, this.onErrorBackSelected]} />
            }
            if (this.props.moreinfo && (this.props.moreinfo.isError || !this.props.moreinfo.isError) && !error && !isLoading) // No error if preferences api call fails
                error = true;
            this.props.showpage.data && this.props.showpage.data[0] ? showpage = <TvShowPage data={this.props.showpage.data}
                onSelected={this.onChannelCardSelected}
                onPlaySelected={this.onPlaySelected}
                onVideoSelected={this.onChannelCardSelected}
                onEpisodeSelected={this.onChannelCardSelected}
                onEpisodeClick={this.onEpisodeClick}
                onButtonSelected={this.onButtonSelected}
                onPosterSelected={this.onPosterSelected}
                styledCarousel={styledEditorial}
                onActionText={this.props.watchlist}
                watchButtonStatus={resumeState}
                isShowPageFocussed={this.props.showpage.data}
                isLoading={false}
                fromSmartTv={true}
                startPolling={this.startPolling}
                isTryThisFocus={this.isBoxOffice(this.props.showpage.data) ? this.props.showpage.data[4] && this.props.showpage.data[4].isTvFocus : this.props.showpage.data[2] && this.props.showpage.data[2].isTvFocus} /> : ''
            if ((this.props && (this.props.showpage.data[1] || error))) {
                return <React.Fragment> {error} {showpage} </React.Fragment>
            }
        }
        return null
    }
}


/**
 * 
 * @param {*} state 
 */
const mapStateToProps = state => {
    return {
        showpage: state.showpage,
        watchlist: state.watchlist,
        catchUp: state.catchUp,
        moreinfo: state.moreinfo
    }
};

/**
 * 
 * @param {*} dispatch 
 */
const mapDispatchToProps = dispatch => ({
    openPlayer: (payload) => dispatch({ type: OPEN_PLAYER, payload }),
    updateShowPage: (payload) => dispatch({ type: UPDATE_SHOWPAGE, payload }),
    closePlayer: () => dispatch({ type: 'CLOSE_PLAYER_UWB' }),
    clearError: (payload) => dispatch({ type: 'SHOWPAGE_ERROR_CLEAR', payload }),
    getButtonAction: (type, watchlistToggleEndpoint, body) => dispatch({ type: type, payload: { watchlistToggleEndpoint, payload: body } }),
    getTryThisData: (data) => dispatch({ type: EDITORIALS, payload: { focusedButton: data, from: "tryThis" } }),
    callAction: (type) => dispatch({ ...type }),
    throwNetworkError: (payload) => dispatch({ type: 'SHOWPAGE_ERROR', payload }),
    callMoreInfoEndPoint: (data) => dispatch({ type: 'WATCH_LIST_MORE_INFO', payload: { moreInfoEndpoint: data } }),
    showOrHideToastMessage: (type, body) => dispatch({ type: type.type, payload: type.body }),
    getEpisodeOverlay: (payload) => dispatch({ type: 'SHOW_EPISODE_OVERLAY', payload }),
    updateShowpageScreen: (type) => dispatch({ type: type.type, payload: type.body })
})

export default connect(mapStateToProps, mapDispatchToProps)(Showpage);